import { type FunctionComponent, useEffect, useState } from 'react';
import { FEATURE_FLAGS } from '../../../constants/general';
import { buildGTMShowroomBookingEvent } from '../../../helpers/analytics/gtm/event-builders';
import { trackGTMEvent } from '../../../helpers/analytics/gtm/gtm-utils.helper';
import { useCustomer } from '../../../hooks/apollo/customer/customer.hooks';
import { useIsBuildDomain } from '../../../hooks/apollo/site/site.hooks';
import { useFeature } from '../../../hooks/features/features.hooks';
import { type ShowroomBookApptEvent } from '../../../types/showroom.types';
import { CreateAccountForm } from '../../account-components/create-account-form/create-account-form.component';
import { CreateAccountStart } from '../../account-components/create-account-start/create-account-start.component';
import { LoginForm } from '../../account-components/login-form/login-form.component';
import { RequestPasswordResetForm } from '../../account-components/request-password-reset-form/request-password-reset-form.component';
import { StyledButton } from '../../buttons';
import { Modal } from '../../common-components/modal/modal.component';
import { OmniHomeSvg } from '../../svg/general.component';
import { FergusonBklLogoWithViewBoxSvg } from '../ferguson-bkl-logo-with-viewbox/ferguson-bkl-logo-with-viewbox.component';
import { ShowroomBookApptIframe } from '../showroom-book-appt-iframe/showroom-book-appt-iframe.component';

type ModalHeaderProps = {
	locationTitle: string | undefined;
	locationCity: string | undefined;
	locationState: string | undefined;
	locationZip: string | undefined;
	customerFirstName: string;
};

const ModalHeader: FunctionComponent<ModalHeaderProps> = ({
	locationTitle,
	locationCity,
	locationState,
	locationZip,
	customerFirstName
}) => {
	const isOmniHomeLogoSchedulerOn = useFeature(FEATURE_FLAGS.OMNI_HOME_LOGO_SCHEDULER);
	const isBuild = useIsBuildDomain();

	return (
		<div className="flex items-center justify-between">
			<div className="flex items-center pr3" style={{ height: '35px' }}>
				{isOmniHomeLogoSchedulerOn ? (
					<OmniHomeSvg className="h-100 pr3" />
				) : isBuild ? (
					<FergusonBklLogoWithViewBoxSvg className="h-100 pr3" />
				) : (
					<OmniHomeSvg className="h-100 pr3" />
				)}
				<div className={'f7-ns flex flex-column inline-flex tl pl3 b--theme-grey-light bl'} style={{ fontSize: '0.5em' }}>
					<div className="nowrap pa0 ma0 fw7">Ferguson {locationTitle}</div>
					<div className="nowrap pa0 ma0 fw3">
						{locationCity}, {locationState} {locationZip}
					</div>
				</div>
			</div>
			<div className={'dn f7-ns flex-ns flex-column tr'} style={{ fontSize: '0.5em' }}>
				<span className="pa0 ma0 fw3 theme-grey">Hello, {customerFirstName}!</span>
			</div>
		</div>
	);
};

type ModalConfirmCancelProps = {
	onDoNotCancelFlow: () => void;
	onCancelFlow: () => void;
};

const ModalConfirmCancel: FunctionComponent<ModalConfirmCancelProps> = ({ onDoNotCancelFlow, onCancelFlow }) => {
	return (
		<div className="center tc pa3 br3 mw7-ns">
			<div className="f3">Are you sure you wish to leave?</div>
			<div className="mt2 mb4">Your appointment will not be scheduled and all changes will be lost.</div>
			<div className="flex justify-center flex-column flex-row-ns" style={{ gap: '32px' }}>
				<StyledButton testId={'do-not-cancel-flow'} buttonStyle={'SECONDARY'} onClick={onDoNotCancelFlow}>
					Return to Booking
				</StyledButton>
				<StyledButton testId={'cancel-flow'} buttonStyle={'DANGER'} onClick={onCancelFlow}>
					Abandon Booking
				</StyledButton>
			</div>
		</div>
	);
};

export type ShowroomAppointmentBookingModalProps = {
	branchPageUrl: string;
	locationTitle?: string;
	locationCity?: string;
	locationState?: string;
	locationZip?: string;
	onCancelFlow: () => void;
	onFinishFlow: () => void;
};

export const ShowroomAppointmentBookingModal: FunctionComponent<ShowroomAppointmentBookingModalProps> = ({
	branchPageUrl,
	locationTitle,
	locationCity,
	locationState,
	locationZip,
	onCancelFlow,
	onFinishFlow
}) => {
	const { data: customerData, hasAccount } = useCustomer();
	const customerFirstName = customerData?.customer.profile.firstName || 'Guest';

	type Mode = 'login' | 'reset_password' | 'create_account' | 'show_flow';
	const [mode, setMode] = useState<Mode>();

	useEffect(() => {
		setMode(hasAccount ? 'show_flow' : 'login');
		if (!hasAccount) {
			trackGTMEvent(buildGTMShowroomBookingEvent('login'));
		}
	}, [hasAccount]);

	const [isFlowComplete, setIsFlowComplete] = useState<boolean>(false);
	const [showConfirmCancel, setShowConfirmCancel] = useState<boolean>(false);
	const onTryCloseModal = () => {
		if (isFlowComplete) {
			onFinishFlow();
		} else {
			setShowConfirmCancel(true);
		}
	};
	const onDoNotCancelFlow = () => {
		trackGTMEvent(buildGTMShowroomBookingEvent('do-not-cancel'));
		setShowConfirmCancel(false);
	};

	const continueToCancel = () => {
		trackGTMEvent(buildGTMShowroomBookingEvent('cancel'));
		onCancelFlow();
	};

	const onFlowEvent = (event: ShowroomBookApptEvent) => {
		const gtmEvent = buildGTMShowroomBookingEvent('flow', event);
		// review event is left undefined in buildGTMShowroomBookingEvent
		// send all events to gtm that aren't the "review" event that doesn't contain booking details.
		if (gtmEvent.step && gtmEvent.step_name) {
			trackGTMEvent(gtmEvent);
		}
		if (event.type === 'cancel') {
			onTryCloseModal();
		} else if (event.type === 'finish') {
			onFinishFlow();
		} else if (event.type === 'stage') {
			// allow closing modal without confirmation if appt has been scheduled
			if (event.detail === 'confirmation') {
				setIsFlowComplete(true);
			} else {
				setIsFlowComplete(false);
			}
		}
	};

	return (
		<>
			{mode === 'show_flow' && hasAccount ? (
				<Modal
					size={'stretch'}
					disableClose={true}
					padBody={false}
					title={
						<ModalHeader
							locationTitle={locationTitle}
							locationCity={locationCity}
							locationState={locationState}
							locationZip={locationZip}
							customerFirstName={customerFirstName}
						/>
					}
					ariaLabel="Book Your Appointment"
					closeAction={onTryCloseModal}>
					{showConfirmCancel && <ModalConfirmCancel onDoNotCancelFlow={onDoNotCancelFlow} onCancelFlow={continueToCancel} />}
					<div className={showConfirmCancel ? 'dn' : 'h-100'} style={{ paddingBottom: '67px' }}>
						<ShowroomBookApptIframe branchPageUrl={branchPageUrl} onEvent={onFlowEvent} />
					</div>
				</Modal>
			) : (
				<Modal
					size={'fit'}
					disableClose={true}
					title={
						<ModalHeader
							locationTitle={locationTitle}
							locationCity={locationCity}
							locationState={locationState}
							locationZip={locationZip}
							customerFirstName={customerFirstName}
						/>
					}
					ariaLabel="Book Your Appointment"
					closeAction={onTryCloseModal}>
					{showConfirmCancel && <ModalConfirmCancel onDoNotCancelFlow={onDoNotCancelFlow} onCancelFlow={onCancelFlow} />}
					<div className={showConfirmCancel ? 'dn' : ''}>
						{mode === 'reset_password' && (
							<div className="w-100 w-50-ns center">
								<RequestPasswordResetForm onBack={() => setMode('login')} />
							</div>
						)}
						{mode === 'login' && (
							<>
								<p className="b">Please login or create an account to book a showroom appointment.</p>
								<div className="flex flex-wrap flex-nowrap-ns tl">
									<div className="w-100 w-50-ns">
										<LoginForm
											onPasswordReset={() => setMode('reset_password')}
											onLoginSuccess={() => setMode('show_flow')}
										/>
									</div>
									<div className="w-100 w-50-ns mt2 mt0-ns ml3-ns">
										<CreateAccountStart onCreateAccount={() => setMode('create_account')} />
									</div>
								</div>
							</>
						)}
						{mode === 'create_account' && (
							<CreateAccountForm onCancel={() => setMode('login')} onSuccess={() => setMode('show_flow')} />
						)}
					</div>
				</Modal>
			)}
		</>
	);
};
