import { type FunctionComponent } from 'react';
import { type ProAssociation } from '../../../types/showroom.types';
import { MaybeLink } from '../../common-components/link/link.component';
import { ExternalImage } from '../../images/external-image/external-image.component';

export type ProAssociationBadgeProps = ProAssociation;
export const ProAssociationBadge: FunctionComponent<ProAssociationBadgeProps> = ({ name, url, logoUrl }) => {
	const displayContent = logoUrl ? (
		<ExternalImage source={logoUrl} options={{ width: 100 }} description={name} />
	) : (
		<p data-testid="pro-association-text">{name}</p>
	);

	return (
		<div>
			<MaybeLink url={url}>{displayContent}</MaybeLink>
		</div>
	);
};

// TODO: SODEV-39213 Get this working with cloudinary forward images
