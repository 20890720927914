import { type FunctionComponent } from 'react';
import type { SocialPlatformName } from '../../types/social-media-section.types';
import { Link } from '../common-components/link/link.component';
import { FacebookIcon, HouzzIcon, InstagramIcon, LinkedinIcon, PinterestIcon, YoutubeIcon } from '../svg/icons.component';
import {
	facebookHover,
	instagramHover,
	pinterestHover,
	youtubeHover,
	houzzHover,
	linkedinHover,
	socialIconsGap,
	socialSectionHeight,
	socialPaddingTop,
	omniSocialSectionHeight
} from './social-media-section.css';

const SOCIAL_MEDIA_DIVIDER = 'br b--theme-grey-light';

const ICON_COMMON_CLASSES = 'theme-black f3';

export type SocialMediaLinkProps = {
	name: SocialPlatformName;
	url: string;
	useDivider?: boolean;
	isLast: boolean;
};

const iconByName: Readonly<{ [platformName in SocialPlatformName]: React.ReactNode }> = {
	facebook: <FacebookIcon className={`${ICON_COMMON_CLASSES} ${facebookHover}`} />,
	instagram: <InstagramIcon className={`${ICON_COMMON_CLASSES} ${instagramHover}`} />,
	pinterest: <PinterestIcon className={`${ICON_COMMON_CLASSES} ${pinterestHover}`} />,
	youtube: <YoutubeIcon className={`${ICON_COMMON_CLASSES} ${youtubeHover}`} />,
	houzz: <HouzzIcon className={`${ICON_COMMON_CLASSES} ${houzzHover}`} />,
	linkedin: <LinkedinIcon className={`${ICON_COMMON_CLASSES} ${linkedinHover}`} />
};

export const SocialMediaLink: FunctionComponent<SocialMediaLinkProps> = ({ name, url, useDivider = false, isLast }) => {
	const icon = iconByName[name] || null;
	return (
		icon && (
			<Link
				url={url}
				ariaLabel={`Find us on ${name}`}
				className={`${useDivider && !isLast && SOCIAL_MEDIA_DIVIDER} ${socialPaddingTop}`}
				openInNewTab={true}>
				{icon}
			</Link>
		)
	);
};

export type SocialMediaSectionProps = {
	urls: Omit<SocialMediaLinkProps, 'useDivider' | 'isLast'>[];
	useDivider?: boolean;
	header?: string;
	className?: string;
};

export const SocialMediaSection: FunctionComponent<SocialMediaSectionProps> = ({ urls, useDivider = true, header, className }) => {
	return (
		<section
			className={`w-100 w-50-ns mt0 b--theme-grey-light flex flex-column justify-between ${className}`}
			data-testid="socialMediaSection">
			{header && <h2 className="b db f5 mt0 mb0-ns mb2">{header}</h2>}
			<div
				className={`flex items-center tc justify-start lh-solid ${socialIconsGap} ${socialSectionHeight} ${omniSocialSectionHeight}`}>
				{urls.map((url, index) => (
					<SocialMediaLink key={index} url={url.url} name={url.name} useDivider={useDivider} isLast={index === urls.length - 1} />
				))}
			</div>
		</section>
	);
};
