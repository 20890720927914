export const SHORT_TO_FULL_DAY_NAME = {
	mon: 'monday',
	tue: 'tuesday',
	wed: 'wednesday',
	thu: 'thursday',
	fri: 'friday',
	sat: 'saturday',
	sun: 'sunday'
};

export const FALLS_CHURCH_STUDIO_BRANCH_PAGE_URL = 'falls-church-va-showroom-1133';

export const SHOWROOM_MEET_EXPERT_PHOTO_PUBLIC_ID =
	'mediabase/site/build.com/images/landing-pages/showroom/e3d6d482093cc87236dc05f9cbc684f5.png';

export const SHOWROOM_MEET_EXPERT_NAME = 'Sasha Brooks';
export const SHOWROOM_MEET_EXPERT_LOCATION = 'San Francisco, CA Showroom';
export const SHOWROOM_MEET_EXPERT_CARD_TITLE = 'Meet with an expert consultant today';
export const SHOWROOM_MEET_EXPERT_CARD_COPY = 'Explore the most innovative products with a Ferguson Home Project Expert';
export const UNIQUE_SELLING_POINT_INPUT_LABEL = 'Find a showroom near you and schedule an appointment';

export const SHOWROOM_PREPARE_TODAY_WITH_PROJECTS_PHOTO_PUBLIC_ID = '/siteassets/showrooms/fh-project-imac.png';
