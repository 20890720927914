import { generatePath } from 'react-router';
import { type LocationTypeEnum, type ShowroomFeaturedBrandsQuery } from '../../__generated__/graphql-client-types';
import { SHOWROOM_LOCATION_LINK } from '../../constants/links';
import {
	PLUMBING_LOCATION_BASE_URL,
	READABLE_LOCATION_TYPES,
	SHORT_TO_FULL_DAY_NAME,
	SUPPORTED_LOCATION_TYPES
} from '../../constants/showroom';
import { type ShowroomAddress, type ShowroomBookApptEvent, ShowroomBookApptEventTypes } from '../../types/showroom.types';

type DisplayAddressParams = {
	city?: string;
	state?: string;
	zipCode?: string;
};
export const displayAddress = ({ city = '', state = '', zipCode = '' }: DisplayAddressParams): string => {
	let displayAddress = '';
	displayAddress += city && `${city}, `;
	displayAddress += state && `${state} `;
	displayAddress += zipCode && `${zipCode}`;

	return displayAddress.trim();
};

type DisplayHoursParams = {
	startTime?: string;
	endTime?: string;
};

export const displayHours = (hours: DisplayHoursParams | null): string => {
	if (!hours) {
		return 'Closed';
	}

	const { startTime, endTime } = hours;
	if (!startTime || !endTime) {
		return 'Closed';
	}

	return `${startTime}–${endTime}`;
};

export const isSupportedLocationType = (locationType: LocationTypeEnum) => {
	return SUPPORTED_LOCATION_TYPES.indexOf(locationType) > -1;
};

export const getHumanReadableLocationType = (locationType: LocationTypeEnum): string | undefined => {
	return READABLE_LOCATION_TYPES[locationType];
};

export const getSupportedLocationType = (locationTypes: LocationTypeEnum[]): LocationTypeEnum | undefined => {
	for (const locationType of SUPPORTED_LOCATION_TYPES) {
		if (locationTypes.includes(locationType)) {
			return locationType;
		}
	}
	return undefined;
};

export const createInfoBoxString = (locationName: string | undefined, url: string, address: ShowroomAddress): string => {
	const { addressLine1, addressLine2, city, state, zipCode, phoneNumber } = address;
	const locationInfoBoxName = (locationName && ` - ${locationName}`) || '';
	const title = `${city}, ${state}${locationInfoBoxName}`;
	let infoBoxString = `<div class="pa2" data-testid="info-box-string">`;
	infoBoxString += url ? `<a href="${url}" class="link" data-testid="showroom-link">${title}</a>` : `<p>${title}</p>`;
	infoBoxString += `<p class="ma0 mt1">${addressLine1}</p>`;
	infoBoxString += addressLine2 ? `<p class="ma0">${addressLine2}</p>` : '';
	infoBoxString += `<p class="ma0">${city}, ${state} ${zipCode}</p>`;
	infoBoxString += phoneNumber ? `<p class="ma0"><span class="b">Phone</span>: ${phoneNumber}</p>` : '';
	infoBoxString += `</div>`;
	return infoBoxString;
};

/**
 * Convert a 3-letter day of the week to the full word (lookup is case-insensitive, and a lowercase value is returned).
 */
export const getFullDayName = (shortDayName: string): string | undefined => {
	return SHORT_TO_FULL_DAY_NAME[shortDayName.toLowerCase()];
};

/**
 * Make a URL for the plumbing location, which must have this format:
 *      `https://www.ferguson.com/branch/${partialBranchPageUrl}-plumbing?locationId=${locationId}`
 * Example:
 *      Plumbing location branchPageUrl: eugene-or-plumbing-pvf-3004
 *      Plumbing URL for that branchPageUrl: https://www.ferguson.com/branch/eugene-or-plumbing?locationId=3004
 *      Build URL for the Showroom also at that address: https://www.build.com/showroom/branch/eugene-or-showroom-3004
 */
export const makePlumbingLocationUrl = (branchPageUrl: string) => {
	const parts = branchPageUrl.split('-');
	const partialBranchPageUrl = parts.slice(0, -2).join('-');
	const locationId = parts[parts.length - 1];
	return `${PLUMBING_LOCATION_BASE_URL}${partialBranchPageUrl}?locationId=${locationId}`;
};

export const makeShowroomLocationLink = (branchPageUrl: string) => branchPageUrl && generatePath(SHOWROOM_LOCATION_LINK, { branchPageUrl });

export const isShowroomBookApptEvent = (event: any): event is ShowroomBookApptEvent => ShowroomBookApptEventTypes.includes(event?.type);

type ShowroomFeaturedBrandsSections = ShowroomFeaturedBrandsQuery['homepage']['sections'][0];
type HomePageFeaturedBrands = Extract<ShowroomFeaturedBrandsSections, { __typename?: 'HomePageFeaturedBrands' }>;

export const isShowroomFeaturedBrands = (args: ShowroomFeaturedBrandsSections): args is HomePageFeaturedBrands => {
	return args.__typename === 'HomePageFeaturedBrands' && args.items !== undefined;
};
