import { type FunctionComponent } from 'react';
import { ClickableElement } from '../../buttons/clickable-element/clickable-element.component';
import { ChevronLeftIcon, ChevronRightIcon } from '../../svg/icons.component';

export type CarouselButtonsWithCountProps = {
	onRightButtonClick: () => void;
	onLeftButtonClick: () => void;
	activeIndex: number;
	totalItems: number;
	className?: string;
};

export const CarouselButtonsWithCount: FunctionComponent<CarouselButtonsWithCountProps> = ({
	onRightButtonClick,
	onLeftButtonClick,
	activeIndex,
	totalItems,
	className
}) => {
	return (
		<div className={`flex w-100 ${className}`} data-testid="carousel-buttons-with-count">
			<div className="f4 flex">
				<ClickableElement ariaLabel="prev-button" onClick={onLeftButtonClick} disabled={activeIndex === 0}>
					<ChevronLeftIcon className={`${activeIndex} carouselButtonsNavigationPrevEl`} />
				</ClickableElement>
				<strong data-testid="count-progress" className="ml3 nt1">
					{activeIndex + 1} / {totalItems}
				</strong>
				<ClickableElement ariaLabel="next-button" onClick={onRightButtonClick} disabled={activeIndex === totalItems - 1}>
					<ChevronRightIcon className={`${activeIndex} carouselButtonsNavigationNextEl ml3`} />
				</ClickableElement>
			</div>
		</div>
	);
};
